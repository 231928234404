<template>
  <div class="scroll-list">
    <EditVideo />
  </div>
</template>

<script>

import EditVideo from '@/components/videos/videoEdit'

export default {
  components: {
    EditVideo
  }
}
</script>

<style>

</style>
