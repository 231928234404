<template>
  <div id="video-edit" :style="`height: ${scrollHeight - 176}px; overflow: auto;`">
    
    <div v-if="!loading" >
      <p class="text-caption filename">Filnavn: {{video.fileName}}</p>
      
      <v-form class="video-edit-elements">
        <v-container>
          <v-row>
              <v-col cols="12" md="3" sm="4" xs="12">
                <v-switch id="published-switch"
                  v-model="video.published"
                  :label="`Publisert: ${video.published ? 'Ja' : 'Nei'}`"
                />
              </v-col>
              <v-col cols="12" md="3" sm="4" xs="12">
                <div id="thumbnail-time-wrapper">
                  <div id="thumbnail-time">
                    <v-btn dense @click="setnewPosterTime" text id="setPosterTime"><v-icon id="camera-icon">mdi-camera-plus</v-icon></v-btn>
                    <img :src="imageUrl" @click="skipToTime(video.posterTime)" />
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3" sm="4" xs="12">
                <v-text-field v-model="video.title"
                  label="Tittel"
                  @keyup.enter="updateVideo"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="4" xs="12">
                <v-select
                  v-model="video.category"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  prefix="Kategori: "
                  persistent-hint
                  return-object
                  single-line
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="4" xs="12">
                <v-btn
                  :loading="saving"
                  class="white--text lagre"
                  color="primary"
                  @click="updateVideo"
                >
                  Lagre endringer
                </v-btn>
              </v-col>
          </v-row>
        </v-container>
      </v-form>

    </div>

    <VideoEditUpload :video="video" />

  </div>
</template>

<script>
import { thumb } from '@/helpers/utils'
import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '@/graphql/mutations'
import * as queries from '@/graphql/queries'
import VideoEditUpload from './videoEditUpload'

export default {
  name: "",
  data () {
    return {
      // errors: [],
      kategorivalg: { name: 'ikke-valgt', id: '0' },
      saving: false,
      img_preview: undefined, // require('@/assets/test-image-cropped.jpg'),
      newPosterTime: 0,
      categories: null
    }
  },
  created () {
    this.listCategories()
    this.getVideo()
  },
  components: {
    VideoEditUpload
  },
  computed: {
    imageUrl () {
      return this.img_preview ? this.img_preview : thumb(this.video.fileName, this.video.posterTime, 300)
    },
    video () {
      return this.$store.state.currentVideo
    },
    playerTime () {
      return this.$store.getters.currentTime
    },
    loading () {
      return this.$store.getters.videoDataLoading
    },
    scrollHeight () {
      return window.innerHeight - this.$store.state.playerHeight
    }
  },
  methods: {
    thumb,
    enableKeys () { this.$store.commit('enableKeys') },
    disableKeys () {
      this.$store.commit('disableKeys')
    },
    async updateVideo () {
      console.log('lagrer this.video ', this.video.title, ', ', this.video.published, ', ', this.newPosterTime)
      this.saving = true
      let result
      console.log(this.video)
      try {
        result =  await API.graphql(graphqlOperation(mutations.updateVideo,
          {
            input: {
              title: this.video.title,
              id: this.video.id,
              posterTime: this.newPosterTime,
              published: this.video.published,
              categoryId: this.video.category.id
          } }))
      } catch (e) {
        console.log('feil', e)
      }
      // this.getVideo()
      this.saving = false
      console.log('res', result)
    },
    getImage () {
      const scale = 0.25
      const video = document.getElementsByTagName('video')[0]
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth * scale
      canvas.height = video.videoHeight * scale
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
      canvas.toDataURL()
      var img = document.createElement('img')
      img.src = canvas.toDataURL()
      this.img_preview = img.src
    },
    // thumb (fileName, time, width) {
    //   return thumb(fileName, time, width)
    // },
    setnewPosterTime () {
      const currentTime = document.getElementsByTagName('video')[0].currentTime
      console.log('this.playerTime', currentTime)
      this.newPosterTime = currentTime
      this.getImage()
    },
    skipToTime (t) {
      this.$store.commit('skipToTime', t)
    },
    getVideo () {
      API.graphql(graphqlOperation(queries.getVideo, { id: this.$route.params.videoId })).then(res => {
        const video = res.data.getVideo;
        this.$store.dispatch('loadVideo', video)
      })
    },
    async listCategories () {
      let result

      try {
        result = await API.graphql({
          query: queries.listCategorys,
          limit: 2000,
          authMode: 'AMAZON_COGNITO_USER_POOLS' // AWS_IAM
        })
        this.categories = result.data.listCategorys.items
        console.log('queries.listCategorys',result)

      } catch (e) {
        console.log(e)
        this.res = e
      }
    },    
  }
}
</script>


<style lang="scss" scoped>
@import '@/styles/variables';
.filename {
  background: rgba(0,0,0,0.3);
  z-index: 100;
  position: absolute;
  top: 5px;
  padding:5px;
}
// #video-edit {
//   padding: 6px;
// }
.video-edit-elements {
  padding-bottom: 5px;
  background-color: #222;
}


#thumbnail-time-wrapper {
  margin-top: 9px;
  // width: 100%;
  background-color: var(--v-secondary-base);
  #thumbnail-time { /* CAMERA ICON and PREVIEW IMAGE */
    @media (min-width: $breakpoint-sm) {
      float: none;
      margin-top: 1px;
    }
    grid-area: thumbnail-time;
    display: grid;
    grid-template-columns: 66px 80px;
    grid-column-gap: 4px;
    #setPosterTime {
      #camera-icon {
        font-size: 18;
      }
      place-self: center;
    }
    img {
      margin-top:2px;
      height: 36px;
      border-radius: 7px;
      border: 2px solid rgb(24, 24, 24);
      &:hover {
        border: 2px solid rgb(95, 195, 253);
      }
      @media (min-width: $breakpoint-sm) {
        height: 40px;
      }
    }
  }
}
</style>
