<template>
  <div id="upload">
    <v-snackbar v-model="snackbar" top>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false"> Lukk </v-btn>
      </template>
    </v-snackbar>

    <div id="upload-fields">
      <v-file-input
        prepend-icon="mdi-movie-open-outline"
        show-size
        placeholder="velg_en_ny_video_fil.mp4"
        hint="klikk for å velge fil"
        chips
        accept="video/mp4"
        Label="Velg video"
        id="file"
        ref="file"
        v-model="file"
      >
      </v-file-input>

      <v-btn
        id="upload-button"
        :loading="uploading"
        class="white--text"
        color="primary"
        @click="uploadVideo"
        :disabled="!ready"
      >
        Last opp
      </v-btn>
    </div>

    <v-progress-linear
      style="margin-top: -8px"
      class="upload-progress"
      :value="progress"
      v-if="uploading"
    >
    </v-progress-linear>
  </div>
</template>

<script>
import { Storage, API } from "aws-amplify";
// import shortid from 'shortid'
import slugify from "@/helpers/slugify";
import { updateVideo } from "@/graphql/mutations";

export default {
  data() {
    return {
      file: undefined,
      uploading: false,
      progress: 0,
      snackbar: false,
      text: "Videoopplasting gikk fint 👍",
      previousKey: undefined,
    };
  },
  props: ["video"],

  methods: {
    async uploadVideo() {
      this.previousKey = this.video.fileName; // For deleting the original video file.
      this.uploading = true;
      const key = this.fileName;
      console.log("key", key);

      Storage.configure({ level: "public" });
      const self = this;

      Storage.put(key, this.file, {
        // public by default but need iam!
        contentType: this.file.type,
        metadata: { title: this.video.title },
        progressCallback(progress) {
          self.progress = (progress.loaded / progress.total) * 100;
        },
      })
        .then(() => {
          // Update the file name and delete the original video file
          this.updateVideo(); // If this failes previous state (video filename and file) should be restored

          self.uploading = false;
          self.progress = 0;
          self.snackbar = true;
          this.$store.dispatch("loadVideo");
          this.$router.push({ name: "editChapters", params: { videoId: this.video.id } });
        })
        .catch((err) => {
          this.uploading = true;
          console.log("Dette gikk galt", err);
        });
    },
    async updateVideo() {
      let result;
      try {
        result = await await API.graphql({
          query: updateVideo,
          authMode: "AMAZON_COGNITO_USER_POOLS",
          variables: {
            input: {
              fileName: this.fileName,
              id: this.video.id,
            },
          },
        });

        console.log("Save result", result);

        Storage.remove(this.previousKey)
          .then((result) => console.log("removed result:", result))
          .catch((err) => console.log(err));
      } catch (e) {
        console.log("feil", e);
      }
    },
  },

  computed: {
    ready() {
      if (this.uploading) {
        return false;
      }
      return this.file;
    },
    fileName() {
      if (this.file) {
        const fileName = this.file.name.split(".")[0];
        const ext = this.file.name.split(".").pop();
        const slug = slugify(fileName);
        // console.log('slug', slug)
        // console.log('this.videoId', this.videoId)
        // console.log('ext', ext)
        return slug + "-" + this.video.id + "." + ext;
      }
      return "ikke-valgt";
    },
    // video () {
    //   return this.$store.state.currentVideo
    // },
  },
};
</script>

<style lang="scss" scoped>
#upload {
  margin-top: 4px;
  background-color: #222;
  padding: 6px;
  // background: blue;
  // width: 360px;
  height: 80px;
  display: grid;
  grid-template-rows: auto 100px;
}
#upload-fields {
  // height: 200px;
  display: grid;
  grid-template-columns: auto 100px;
  grid-column-gap: 12px;
  #upload-button {
    margin-top: 14px;
  }
  .upload-progress {
    background: red;
    margin-bottom: 8px;
  }
}
</style>
